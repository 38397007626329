.hero-wrapper{
    color: white;
    /* position: relative; */
    padding-bottom: 2rem;
}

.hero-container{
    justify-content: space-around;
    align-items: flex-end;
}

/* Left Side */

.hero-left{
    gap: 3rem;

}

.hero-title{
    position: relative;
    z-index: 1;
}

.hero-title>h1{
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 4rem;
}

.orange-circle{
    height: 4rem;
    width: 4rem;
    background: var(--orange-gradient);
    border-radius: 999px;
    position: absolute;
    right: 28%;
    top: -10%;
    z-index: -1;
}

.search-bar{
    background-color: #fff;
    border-radius: 5px;
    border: 3px solid rgba(120, 120, 120, 0.374);
    padding: 0.5rem 1rem;
    justify-content: space-around;
    width: 100%;
}

.search-bar>input{
    border: none;
    outline: none;
}

.stats{
    width: 100%;
    justify-content: space-between;
}

.stat>:nth-child(1)
{
    font-size: 2rem;
}

.stat>:nth-child(1)>:last-child{
    color: orange;
}

/* Right Side */

.image-container{
    width: 30rem;
    height: 35rem;
    overflow: hidden;
    border-radius: 15rem 15rem 0 0;
    border: 8px solid rgba(255, 255, 255, 0.12);
}

.image-container>img{
  width: 100%;
  height: 100%;
  
}

@media (max-width: 640px) {
    .hero-container{
        margin-top: 2rem;
    }   
    .hero-title>h1{
        font-size: 2.5rem;
        line-height: 3rem;
    }
    .image-container{
        width: 95%;
        height: 25rem;
    }
    .stats{
        justify-content: center;
        gap: 1.5rem;
    }
    .search-bar{
        width: 100%;
    }
    .hero-right{
        width: 100%;
    }
    .stat>:nth-child(1)
    {
        font-size: 1.5rem;
    }
    .stat>:nth-child(2)
    {
        font-size: 0.8rem;
    }
}